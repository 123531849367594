import React, { Component } from "react";
import axios from "axios";
import { TextField } from "@material-ui/core";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import DialogSuccess from "../../components/Dialog/DialogSuccess";
import DialogError from "../../components/Dialog/DialogError";
import LoadingScreen from "react-loading-screen";
import FooterComponent from "../../components/FooterComponent";
import config from "../../config";
import "./style.css";
import "../../assets/bootstrap.css";

class JoyRideBookingComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      block: false,
      bookingId: null,
      successMessage: "",
      email: "",
      mobileNumber: "",
      seats: 1,
      passengers: [],
      grandprice: "",
      errors: {
        email: false,
        mobileNumber: false,
        passengers: {
          name: [],
          age: [],
          weight: [],
          idNumber: [],
        },
      },
      travelDate: null,
      selectedDateFormatted: null,
      selectedScheduleObj: null,
      bookingResponse: {},
      dialog: {
        error: false,
        success: false,
      },
      isFormValid: true,
    };
  }

  getQueryParam = (key) => {
    const { search } = this.props.location;
    let value = null;
    search
      .replace("?", "")
      .split("&")
      .some((param) => {
        const [paramKey, paramValue] = param.split("=");

        if (key === paramKey) {
          value = paramValue;
          return false;
        }
        return true;
      });

    return value;
  };

  async componentDidMount() {
    const pageState = this.props.history.location.state;
    const bookingId = this.getQueryParam("id");

    if (bookingId) {
      const { booking } = await axios
        .get(config.baseUrl + "v2/booking/block?bookingId=" + bookingId)
        .then((res) => res.data);

      if (!booking) {
        this.navigateToJoyRides();
      }

      const {
        travelDate,
        schedule: selectedScheduleObj,
        seatCount: seats,
        email,
        mobileNumber,
      } = booking;

      const passengers = [];

      for (let i = 0; i < seats; i++) {
        passengers.push({});
      }

      this.setState(
        {
          travelDate,
          selectedDateFormatted: travelDate,
          loading: false,
          selectedScheduleObj,
          block: true,
          email,
          mobileNumber,
          bookingId,
          seats,
          passengers,
        },
        this.onSeatCountChange
      );

      return;
    }

    if (pageState) {
      const {
        selectedDateFormatted,
        selectedScheduleObj,
        date: travelDate,
      } = pageState;

      const minimumTicketsPerBooking =
        selectedScheduleObj.minimumTicketsPerBooking || 1;

      this.setState(
        {
          selectedDateFormatted,
          selectedScheduleObj: {
            ...selectedScheduleObj,
            minimumTicketsPerBooking,
          },
          travelDate,
          loading: false,
          seats: minimumTicketsPerBooking,
        },
        this.onSeatCountChange
      );
      return;
    }

    this.navigateToJoyRides();
  }

  navigateToJoyRides = () => {
    window.location.search = "";
    window.location.pathname = "/joy-rides";
  };

  validateEmail(email) {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleChange(field, value) {
    this.setState({
      [field]: value,
    });

    if (field === "seats") {
      this.onSeatCountChange(value);
    }
  }

  onSeatCountChange = (seatCount = this.state.seats) => {
    const { selectedScheduleObj, passengers, block, seats } = this.state;
    const currentSeats = passengers.length;
    const seatAllowed = block ? seats : seatCount;

    if (currentSeats < seatAllowed) {
      for (let i = 0; i < seatAllowed - currentSeats; i++) {
        passengers.push({});
      }
    } else {
      passengers.splice(seatAllowed);
    }

    let grandprice = 0;

    if (selectedScheduleObj.basePrice && selectedScheduleObj.gstRate) {
      grandprice = parseFloat(
        selectedScheduleObj.basePrice *
          seatAllowed *
          (1 + selectedScheduleObj.gstRate / 100)
      );
    } else {
      grandprice = parseFloat(
        selectedScheduleObj.priceLineItems.reduce((acc, curr) => {
          return acc + curr.price;
        }, 0) * seatAllowed
      ).toFixed(2);
    }

    this.setState({
      grandprice: `₹ ${grandprice}`,
      passengers,
    });
  };

  addPassengerDetails = (index, field, value) => {
    const { passengers } = this.state;
    passengers[index][field] = value;
    this.setState({ passengers });
  };

  isFormValid = () => {
    const { email, mobileNumber, errors, seats, passengers } = this.state;
    let isFormValid = true;

    if (!this.validateEmail(email)) {
      errors.email = true;
      isFormValid = false;
    } else {
      errors.email = false;
    }

    if (!mobileNumber || !`${mobileNumber}`.trim().length === 10) {
      errors.mobileNumber = true;
      isFormValid = false;
    } else {
      errors.mobileNumber = false;
    }

    for (let i = 0; i < seats; i++) {
      const passenger = passengers[i];

      if (!passenger || !passenger.age || parseInt(passenger.age, 10) <= 0) {
        isFormValid = false;
        if (errors.passengers.age.indexOf(i) === -1)
          errors.passengers.age.push(i);
      } else {
        if (errors.passengers.age.indexOf(i) !== -1) {
          const index = errors.passengers.age.indexOf(i);
          errors.passengers.age.splice(index, 1);
        }
      }

      if (!passenger || !passenger.name || passenger.name.trim().length <= 2) {
        isFormValid = false;
        if (errors.passengers.name.indexOf(i) === -1)
          errors.passengers.name.push(i);
      } else {
        if (errors.passengers.name.indexOf(i) !== -1) {
          const index = errors.passengers.name.indexOf(i);
          errors.passengers.name.splice(index, 1);
        }
      }

      if (
        !passenger ||
        !passenger.weight ||
        parseInt(passenger.weight, 10) <= 1
      ) {
        isFormValid = false;
        if (errors.passengers.weight.indexOf(i) === -1)
          errors.passengers.weight.push(i);
      } else {
        if (errors.passengers.weight.indexOf(i) !== -1) {
          const index = errors.passengers.weight.indexOf(i);
          errors.passengers.weight.splice(index, 1);
        }
      }

      if (
        !passenger ||
        !passenger.idNumber ||
        passenger.idNumber.trim().length <= 2
      ) {
        isFormValid = false;
        if (errors.passengers.idNumber.indexOf(i) === -1)
          errors.passengers.idNumber.push(i);
      } else {
        if (errors.passengers.idNumber.indexOf(i) !== -1) {
          const index = errors.passengers.idNumber.indexOf(i);
          errors.passengers.idNumber.splice(index, 1);
        }
      }
    }

    this.setState({
      errors: {
        ...this.state.errors,
        ...errors,
      },
      isFormValid,
    });

    return isFormValid;
  };

  async createbooking() {
    const isFormValid = this.isFormValid();
    if (!isFormValid) return false;
    this.setState({ loading: true });
    const {
      selectedScheduleObj: { _id: schedule },
      travelDate,
      seats: seatCount,
      passengers,
      email,
      bookingId,
      mobileNumber,
      block,
    } = this.state;

    var payload = {
      bookingId: block ? bookingId : undefined,
      schedule,
      travelDate,
      seatCount,
      passengers: JSON.stringify(passengers),
      email,
      mobileNumber,
    };
    try {
      const response = await axios({
        url: block
          ? config.baseUrl + "v2/booking/block"
          : config.baseUrl + "booking/joyride",
        method: block ? "put" : "post",
        data: payload,
      });
      this.setState({ loading: false });
      let state = { dialog: {}, bookingResponse: {} };

      if (response.data.status === "success") {
        state.dialog.success = true;
        state.dialog.error = false;
        state.bookingResponse = response.data;
      } else {
        state.bookingResponse.message = response.data.message;
        state.dialog.error = true;
        state.dialog.success = false;
      }

      this.setState({
        ...this.state,
        ...state,
        loading: false,
      });
    } catch (err) {
      this.setState({
        ...this.state,
        dialog: {
          error: true,
          success: false,
        },
        loading: false,
        bookingResponse: {
          message: "Uh oh! Something has gone wrong !",
        },
      });
    }
  }

  closeDialog = () => {
    this.setState({
      dialog: {
        error: false,
        success: false,
      },
    });
  };

  isSeatChoiceValid = (seat) => {
    const { selectedScheduleObj } = this.state;
    const { minimumTicketsPerBooking = 1 } = selectedScheduleObj || {};

    return seat >= minimumTicketsPerBooking;
  };

  render() {
    const {
      selectedScheduleObj,
      selectedDateFormatted,
      grandprice,
      seats,
      errors,
      block,
      dialog,
      bookingResponse,
    } = this.state;

    var passengerRows = [];

    for (let i = 0; i < seats; i++) {
      passengerRows.push(
        <div key={i}>
          <div style={{ padding: "0 15px", display: "inline-block" }}>
            <TextField
              label="Name"
              type="text"
              error={errors.passengers.name.indexOf(i) !== -1}
              onChange={(e) => {
                this.addPassengerDetails(i, "name", e.target.value);
              }}
              margin="normal"
              variant="outlined"
            />
          </div>
          <div style={{ padding: "0 15px", display: "inline-block" }}>
            <TextField
              label="Age"
              type="number"
              onChange={(e) => {
                this.addPassengerDetails(
                  i,
                  "age",
                  parseInt(e.target.value, 10)
                );
              }}
              error={errors.passengers.age.indexOf(i) !== -1}
              margin="normal"
              variant="outlined"
            />
          </div>
          <div style={{ padding: "0 15px", display: "inline-block" }}>
            <TextField
              label="Weight"
              type="number"
              margin="normal"
              onChange={(e) => {
                this.addPassengerDetails(
                  i,
                  "weight",
                  parseInt(e.target.value, 10)
                );
              }}
              error={errors.passengers.weight.indexOf(i) !== -1}
              variant="outlined"
            />
          </div>
          <div style={{ padding: "0 15px", display: "inline-block" }}>
            <TextField
              label="Govt. ID Number"
              type="text"
              onChange={(e) => {
                this.addPassengerDetails(i, "idNumber", e.target.value);
              }}
              error={errors.passengers.idNumber.indexOf(i) !== -1}
              margin="normal"
              variant="outlined"
            />
          </div>
        </div>
      );
    }

    return (
      <div>
        <DialogSuccess
          open={dialog.success}
          data={bookingResponse}
          block={block}
        />
        <DialogError
          open={dialog.error}
          data={bookingResponse}
          handleClose={this.closeDialog}
        />
        <LoadingScreen
          loading={this.state.loading}
          bgColor="#005299"
          spinnerColor="#9ee5f8"
          textColor="#f7ca18"
          logoSrc="/assets/icon.png"
          text="Please wait, Booking in progress..."
        >
          <section className="home-jumbotron" style={{ background: "none" }}>
            <div className="joyrideItemContainerBooking">
              <div className="container" style={{ border: "1px solid black" }}>
                <div className="row ">
                  <div
                    style={{
                      display: "block",
                      width: "100%",
                      fontWeight: 500,
                      paddingLeft: "15px",
                      textAlign: "center",
                      padding: "20px 0",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        borderBottom: "1px solid black",
                        display: "inline-block",
                      }}
                    >
                      Ticket Details
                    </div>
                  </div>

                  <div className="col-md-3">
                    <h4 style={{ fontSize: "18px" }}>Flying at</h4>
                    <h3>
                      {selectedScheduleObj &&
                        selectedScheduleObj.path.halts[0].name}
                    </h3>
                  </div>
                  <div className="col-md-3">
                    <h4>Date</h4>
                    <h3>{selectedDateFormatted}</h3>
                  </div>
                  <div className="col-md-6">
                    <h4>Timings</h4>
                    <h3>{selectedScheduleObj && selectedScheduleObj.name}</h3>
                  </div>
                  <div className="col-md-3">
                    <h4>Payable amount</h4>
                    <h3>{grandprice}</h3>
                  </div>

                  <div className="row ridedetails ridedetailsinfo">
                    <div className="form-group col-md-3">
                      <TextField
                        fullWidth
                        label="Email Address"
                        disabled={block}
                        variant="outlined"
                        error={errors.email}
                        value={this.state.email}
                        onChange={(e) =>
                          this.handleChange("email", e.target.value)
                        }
                        margin="normal"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <TextField
                        fullWidth
                        label="Mobile Number"
                        disabled={block}
                        error={errors.mobileNumber}
                        value={this.state.mobileNumber}
                        variant="outlined"
                        onChange={(e) =>
                          this.handleChange("mobileNumber", e.target.value)
                        }
                        margin="normal"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <SelectField
                        floatingLabelText="Seats"
                        disabled={block}
                        style={{ width: "100px" }}
                        variant="outlined"
                        onChange={(e, i, v) => this.handleChange("seats", v)}
                        value={seats}
                      >
                        {[1, 2, 3, 4, 5, 6]
                          .filter(this.isSeatChoiceValid)
                          .map((i) => (
                            <MenuItem primaryText={`${i}`} value={i} key={i} />
                          ))}
                      </SelectField>
                    </div>
                    <div
                      style={{
                        display: "block",
                        width: "100%",
                        fontSize: "16px",
                        fontWeight: 500,
                        paddingLeft: "15px",
                      }}
                    >
                      Passenger Details
                    </div>
                    {passengerRows}

                    <div className="form-group col-md-12">
                      <button
                        type="button"
                        className="btn btn-success"
                        style={{ float: "right", padding: "10px" }}
                        onClick={this.createbooking.bind(this)}
                      >
                        Pay {grandprice}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <FooterComponent />
        </LoadingScreen>
      </div>
    );
  }
}

export default JoyRideBookingComponent;
