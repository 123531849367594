import React, { useEffect, Fragment, useState, useCallback } from "react";
import "./index.css";
import { NavLink } from "react-router-dom";
import FooterComponent from "../../components/FooterComponent";
import { Loading } from "../../components/Loading";

const Card = ({
  id,
  badgeText,
  subTitle,
  mainTitle,
  time,
  price,
  priceType,
  style = {},
  description = "",
  bookNowLink = (
    <NavLink to="/bookwithus">
      <button className="desktop-button">Book Now</button>
    </NavLink>
  ),
  previousPrice = price,
}) => {
  let priceNode = `₹${price.toLocaleString("en-IN")}/-`;

  if (previousPrice > price) {
    priceNode = (
      <Fragment>
        <span
          style={{
            textDecoration: "line-through",
            color: "#999",
            marginRight: "5px",
            fontSize: "20px",
          }}
        >
          ₹{previousPrice.toLocaleString("en-IN")}
        </span>
        <span className="card_price">₹{price.toLocaleString("en-IN")}/-</span>
      </Fragment>
    );
  }

  return (
    <div className="helitaxii_card" style={style}>
      <div className="card_badge">{badgeText}</div>
      <div className="card_subTitle">{subTitle}</div>
      <div className="card_title">{mainTitle}</div>
      <div className="card_passengers">{time}</div>
      <div className="card_description">{description}</div>
      <div className="card_price">{priceNode}</div>
      <div className="card_price_type">{priceType}</div>
      <div className="buttonWrapper">{bookNowLink}</div>
    </div>
  );
};

export default () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const getPackages = useCallback(() => {
    setPackages([
      {
        id: 1,
        title: "Trivandrum",
        subTitle: (
          <span>
            29<sup>th</sup>Dec - 03<sup>rd</sup> Jan 2025
          </span>
        ),
        time: "Aerial Experience ride on a helicopter in Trivandrum",
        description: "🎁 Special discounted price of ₹3,500/- on 2nd January.",
        badgeText: "Aerial Experience",
        priceType: "Per seat",
        price: 4200,
        previousPrice: 4200,
      },
    ]);
    setLoading(false);
  }, []);

  useEffect(() => {
    getPackages();
  }, [getPackages]);

  return (
    <Fragment>
      <div className="homeWrapper">
        <div className="cardHolder">
          <div className="titleOne">
            29<sup>th</sup>Dec - 03<sup>th</sup> Jan 2025
          </div>
          <div className="titleTwo">Trivandrum Aerial Experience rides</div>
          <div className="titleThree">
            Brand new <b>Airbus H125</b> Helicopter 2022 model
          </div>
          <div className="cardsWrapper">
            {loading && <Loading />}
            {packages.map((p) => (
              <Card
                key={p.id}
                id={p.id}
                badgeText={p.badgeText}
                subTitle={p.subTitle}
                mainTitle={p.title}
                time={p.time}
                price={p.price}
                priceType={p.priceType}
                previousPrice={p.previousPrice}
                description={p.description}
              />
            ))}
          </div>
        </div>
        <div className="imageHolder"></div>
      </div>
      <FooterComponent />
    </Fragment>
  );
};
