import React, { Component } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import Menu from "material-ui/svg-icons/navigation/menu";
import Close from "material-ui/svg-icons/navigation/close";
import moment from "moment";

class HeaderComponent extends Component {
  state = {
    showMenu: false,
  };
  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    });
  };
  render() {
    const { corporate } = this.props;
    const showBanner = moment().isSameOrBefore(
      moment("2023-09-23").endOf("day")
    );

    return (
      <React.Fragment>
        {/* Static Banner */}

        <div className="announcement-banner">
          New Year bookings coming soon!
        </div>

        {corporate ? (
          <ul className="c-nav c-nav--inline">
            <a href="/#top">
              <li className="c-nav__item home" />
            </a>
          </ul>
        ) : (
          <ul className="c-nav c-nav--inline" style={{ top: 0 }}>
            <li
              className="c-nav__item collapse-menu-button"
              onClick={this.toggleMenu}
            >
              <span
                style={{
                  display: this.state.showMenu ? "none" : "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Menu style={{ color: "white" }} />
              </span>
              <span
                style={{
                  display: this.state.showMenu ? "flex" : "none",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Close style={{ color: "white" }} />
              </span>
            </li>
            <Link className="nav_item nav_item_home" to="">
              <li className="c-nav__item ">
                <img
                  className="logoimage"
                  alt="logo"
                  src="/assets/HeliTaxii-logo.png"
                />
              </li>
            </Link>
            <Link
              className="nav_item large-device-menu"
              to="chardham-by-helicopter"
            >
              <li className="c-nav__item">Chardham by Helicopter</li>
            </Link>
            <Link className="nav_item large-device-menu" to="airport-shuttle">
              <li className="c-nav__item">Airport Shuttle</li>
            </Link>
            <Link
              className="nav_item large-device-menu"
              to="emergency-services"
            >
              <li className="c-nav__item">Emergency Services</li>
            </Link>
            <Link className="nav_item large-device-menu" to="joy-rides">
              <li className="c-nav__item">Joy Rides</li>
            </Link>
            <Link className="nav_item large-device-menu" to="private-charters">
              <li className="c-nav__item">Private Charters</li>
            </Link>
            <ul
              className={
                (this.state.showMenu ? "visible" : "hidden") +
                " c-card c-card--menu collapse-menu"
              }
            >
              <Link to="">
                <li onClick={this.toggleMenu} className="c-nav__item">
                  Home
                </li>
              </Link>
              <Link to="chardham-by-helicopter">
                <li onClick={this.toggleMenu} className="c-nav__item">
                  Chardham by Helicopter
                </li>
              </Link>
              <Link to="airport-shuttle">
                <li onClick={this.toggleMenu} className="c-nav__item">
                  Airport Shuttle
                </li>
              </Link>
              <Link to="emergency-services">
                <li onClick={this.toggleMenu} className="c-nav__item">
                  Emergency Services
                </li>
              </Link>
              <Link to="joy-rides">
                <li onClick={this.toggleMenu} className="c-nav__item">
                  Joy Rides
                </li>
              </Link>
              <Link to="private-charters">
                <li onClick={this.toggleMenu} className="c-nav__item">
                  Private Charters
                </li>
              </Link>
            </ul>
          </ul>
        )}
      </React.Fragment>
    );
  }
}

export default HeaderComponent;
